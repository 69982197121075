import React from 'react'
import StyledExpandAll from './style'

const ExpandButton = ({ children }) => (
  <StyledExpandAll>
    {children}
  </StyledExpandAll>
)

export default ExpandButton
