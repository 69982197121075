import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { Flex, Box, Text } from 'rebass'
import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import Heading, { StyledHeading } from '../components/elements/Heading'
import BodyText from '../components/elements/BodyText'
import ExpandAllButton from '../components/elements/ExpandAllButton'
import AccordionItem, { StyledAccordionItem } from '../components/elements/AccordionItem'
import theme from '../themes'

const StyledPrivacyPolicyPage = styled.div`
  a {
    box-shadow: none;
    border: none;
    cursor: pointer;
    font-family: 'Overpass', 'Helvetica', sans-serif;
    text-decoration: underline;

    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }

    &:focus {
      outline: none;
    }
  }

  h2 {
    letter-spacing: -2px;
  }

  > ${Flex} {
    > ${Box} {
      max-width: 1168px;
    }
  }

  ${StyledAccordionItem} {
    ${StyledHeading} {
      position: relative;
      padding-top: 1em;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border-radius: 100vw;
        background: rgba(255, 0, 0, 0.5);
        width: 30px;
        height: 5px;
        transform: translate(0, -50%);
      }
    }
  }
`

const PrivacyPolicyPage = (props) => {
  const [expandAll, setExpandAll] = useState(false)

  const accordionItems = []

  const toggleExpandAll = () => {
    setExpandAll(!expandAll)
  }

  const sections = props.data.allContentfulSections
  const privacyPolicy = sections.edges[6].node
  const paragraphs = privacyPolicy.sectionBodyCopy.sectionBodyCopy.split('##')

  const introParagraph = paragraphs.shift()
  const parsedParagraphs = paragraphs.map((paragraph) => {
    const split = paragraph.split(/\n/)
    return {
      heading: split.shift(),
      body: split.join('\n')
    }
  })

  useEffect(() => {
    if (expandAll === true) {
      accordionItems.map(item => item.expand())
    } else {
      accordionItems.map(item => item.collapse())
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title="Privacy Policy | POWERSHiFTER"
        description="This privacy policy governs the manner in which POWERSHiFTER collects, uses, maintains, and discloses information collected from users."
        canonical="https://powershifter.com/privacy-policy/"
      />
      <StyledPrivacyPolicyPage>
        <Header />
        <Flex mt={[4, null, null, 6]}>
          <Box mx="auto" my={[6]} px={[4, null, null, null, 0]}>
            <Heading is="h1">{privacyPolicy.sectionHeadline}</Heading>
            <BodyText>{introParagraph}</BodyText>
          </Box>
        </Flex>
        <Flex mb={6} flexDirection="column">
          <Box
            mx="auto"
            mb={[6]}
            width={1}
            px={[4, null, null, null, 0]}
            onClick={toggleExpandAll}
          >
            <Text textAlign="right">
              <ExpandAllButton>
                {expandAll ? 'Collapse all' : 'Expand all'}
              </ExpandAllButton>
            </Text>
          </Box>
          {parsedParagraphs.map(paragraph => (
            <AccordionItem
              key={paragraph.heading}
              ref={(item) => {
                accordionItems.push(item)
              }}
            >
              <Heading>{paragraph.heading}</Heading>
              <BodyText>{paragraph.body}</BodyText>
            </AccordionItem>
          ))}
        </Flex>
        <Footer />
      </StyledPrivacyPolicyPage>
    </ThemeProvider>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  {
    allContentfulSections(sort: { fields: [order] }) {
      edges {
        node {
          name
          sectionHeadline
          sectionBodyCopy {
            sectionBodyCopy
          }
          sectionContent {
            __typename

            ... on ContentfulListItemText {
              heading
              body {
                body
              }
            }
          }
        }
      }
    }
  }
`
