import styled from 'styled-components'

const StyledExpandAll = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  font-family: 'Overpass', 'Helvetica', sans-serif;
  font-size: 1rem;
  text-decoration: underline;

  &:active {
    color: ${props => props.theme.colors.linkActive};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
`

export default StyledExpandAll
