import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Flex } from 'rebass'
import takeFrom from '../../../__utils__/helpers'
import Heading from '../Heading'
import BodyText from '../BodyText'

const StyledAccordionItem = styled.div`
  background-color: #f0f0f0;
  border-bottom: 1px solid #dedede;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  a {
    color: ${props => props.theme.colors.primary};
  }

  ${Flex} {
    > ${Flex} {
      max-width: 1168px;
    }
  }
`

const StyledBox = styled(Box)`
  text-align: right;
  color: #0060ff;
  text-decoration: underline;
`

class AccordionItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }

    this.toggleExpand = this.toggleExpand.bind(this)
  }

  expand() {
    this.setState({
      expanded: true
    })
  }

  collapse() {
    this.setState({
      expanded: false
    })
  }

  toggleExpand() {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }))
  }

  render() {
    const children = React.Children.toArray(this.props.children)

    return (
      <StyledAccordionItem {...this.props} onClick={this.toggleExpand}>
        <Flex>
          <Flex
            className="content"
            width={1}
            mx="auto"
            flexDirection="column"
            py={[5, null, null, 5]}
            pl={[4, 4, null, null]}
          >
            <Flex alignItems="center">
              <Box width={[1, null, null, 12 / 12]}>
                {takeFrom(children, <Heading />, {
                  fontSize: [2, 2, 3, 3],
                  postsymbol: ''
                })}
              </Box>
              <StyledBox width={1} mr={[4, null, null, null, 0]}>
                {this.state.expanded ? 'Collapse' : 'Expand'}
              </StyledBox>
            </Flex>
            {this.state.expanded && (
              <Box
                mx="auto"
                width={1}
                px={[4, null, null, null, 0]}
                mt={[0]}
                mb={[1, 1, 1, 1]}
              >
                {takeFrom(children, <BodyText />)}
              </Box>
            )}
          </Flex>
        </Flex>
      </StyledAccordionItem>
    )
  }
}

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired
}

AccordionItem.defaultProps = {}

export { AccordionItem as default, StyledAccordionItem }
